@import "../node_modules/bulma/bulma.sass";

body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.desktop-nav-bar {
  width: 20%;
}

.mobile-nav-bar {
  width: 10%;
}

.has-bg-img-2 {
  background: url('/images/Vivo_boardroom.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom -12em;
  position: relative;
  padding: 3em 0em 30em;
  background-position: center;
}

.has-bg-img {
  background: url('/images/valueProp.jpeg');
  background-size: cover;
  background-position: center;
  // opacity: 0.4;
}

.social-proof-container {
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
}

.social-proof-container-mobile {
  width: 115%;
  margin-left: -7.5%;
}

.contact-us-container {
  // position: absolute;
  // bottom: 5%;
  // left: 1%;
}

.container .quote {
  width: 46%;
  height: 46%;
  margin-left: 25%;
  flex: 0 0 auto;
  border-radius: 5px;

}

.horizontal-card-container {
  display: flex;
  flex-wrap: nowrap;
  width: 80%;
  margin-left: 10%;
  // overflow-y: hidden;
  // overflow-x: auto;
  // white-space: nowrap;
  // -webkit-overflow-scrolling: touch;

  .quote {
    width: 46%;
    height: 46%;
    margin-right: 3%;
    flex: 0 0 auto;
    border-radius: 5px;

    .card-footer {
      display: flex;
      background-color: transparent;
      border-top: 1px solid #ededed;
      align-items: stretch;
      padding: 0;

      .card-footer-item {
        align-items: center;
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: center;
      }
    }
  }
}